import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { charityTheme } from 'common/theme/charity';
import { ResetCSS } from 'common/assets/css/style';
import Navbar from 'components/Navbar';
import {
  GlobalStyle,
  CharityWrapper,
} from 'containers/Charity/charity.style';
import Seo from 'components/seo';

const Index = () => {
  return (
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <Seo title="Charity" />
        <ResetCSS />
        <GlobalStyle />
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          404
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
export default Index;
