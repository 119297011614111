import React from 'react';
import { Link } from 'gatsby';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import NavbarWrapper, { MenuWrapper, Button } from './navbar.style';

import logoImage from './logo.png';

const Navbar = () => {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <NavbarWrapper className="navbar">
      <Container fullWidth={true} >
        <Link className="logo" to="/">
          <Image src={logoImage} alt="Godalming & Villages Residents Logo" />
        </Link>
      </Container>
    </NavbarWrapper>
  );
};

export default Navbar;
